import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import exportLimit from "../../common/export-limit.vue";
import materialService from "./material-movement-service";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      totalRecords: 0,
      exportDisable: true,
      excelName: "",
      export50kData: false,
      exportDialog: false,
      showData: false,
      runDisabled: false,
      dateSelected: false,
      lpnSearchNum: "",
      showLPN: false,
      showContinue: false,
      showReason: false,
      selectedReason: "",
      searchKey: "",
      reasonList: [],
      lpnAll: false,
      lpnSelected: false,
      headerLPNActivity: [
        { text: "LPN", align: "start", value: "LPN", class: "primary customwhite--text" },
        { text: "Log Date", value: "Log Date", class: "primary customwhite--text" },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        { text: "Part#", value: "Part#", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Serial Number", value: "Serialno", class: "primary customwhite--text" },
        { text: "From Loc", value: "From Loc", class: "primary customwhite--text" },
        { text: "From Bin", value: "From Bin", class: "primary customwhite--text" },
        { text: "From LPN", value: "From LPN", class: "primary customwhite--text" },
        { text: "To Loc", value: "To Loc", class: "primary customwhite--text" },
        { text: "To Bin", value: "To Bin", class: "primary customwhite--text" },
        { text: "To LPN", value: "To LPN", class: "primary customwhite--text" },
        { text: "SO#", value: "SO#", class: "primary customwhite--text" },
        { text: "PO#", value: "PO#", class: "primary customwhite--text" },
        { text: "Line", value: "Line", class: "primary customwhite--text" },
        { text: "Reason", value: "Reason", class: "primary customwhite--text" },
        { text: "PI ID", value: "PI ID", class: "primary customwhite--text" },
      ],
      json_fields: {
        LPN: "LPN",
        "Log Date": "Log Date",
        Tech: "Tech",
        "Part#": "Part#",
        Quantity: "Qty",
        BCN: "BCN",
        "Serial Number": "Serialno",
        "From Loc": "From Loc",
        "From Bin": "From Bin",
        "From LPN": "From LPN",
        "To Loc": "To Loc",
        "To Bin": "To Bin",
        "To LPN": "To LPN",
        "SO#": "SO#",
        "PO#": "PO#",
        Line: "Line",
        Reason: "Reason",
        "PI ID": "PI ID",
      },
      searchLPNRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",

      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    onClickSearch() {
      let obj = {
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        pass: 3,
        lpn: "",
        all: 0,
        searchStr: this.searchKey,
      };
      this.getReportData(obj)
    },
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.totalRecords = 0;
      this.exportDisable = true;
      this.lpnActivityData = [];
      this.runDisabled = false;
      this.excelName = "";
      this.export50kData = false;
      this.exportDialog = false;
      this.showData = false;
      this.dateSelected = false;
      this.lpnSearchNum = "";
      this.showLPN = false;
      this.showContinue = false;
      this.showReason = false;
      this.selectedReason = "";
      this.reasonList = [];
      this.lpnAll = false;
      this.lpnSelected = false;
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //Go button click event
    async goClick() {
      this.showLPN = true;
      this.dateSelected = true;
      this.showContinue = true;
    },
    lpnNumFormat(event, num) {
      Utility.formatLPNNumber(event, num);
      this.lpnSearchNum = Utility.formatLPNNumber(event, num);
    },
    //Continue button click event
    async onContinue() {
      if (this.lpnAll) {
        this.lpnSearchNum = "";
        !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
        let obj = {
          user_id: parseInt(this.userId),
          lcsdate: Utility.convertLocalToUTC(this.sDate),
          lcedate: Utility.convertLocalToUTC(this.eDate),
          pass: 1,
          lpn: "",
          all: this.lpnAll == true ? 1 : 0,
          searchStr: ""
        };
        let showMessage = false;
        let data = await materialService.lpnActivityReport("post", obj, showMessage);
        if (data.message !== "NA") {
          this.$refs.lpnDetailsForm.resetValidation();
          this.reasonList = data;
          this.lpnSelected = true;
          this.showContinue = false;
          this.showReason = true;
        }
      } else {
        if (this.$refs.lpnDetailsForm.validate()) {
          !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
          let obj = {
            user_id: parseInt(this.userId),
            lcsdate: Utility.convertLocalToUTC(this.sDate),
            lcedate: Utility.convertLocalToUTC(this.eDate),
            pass: 1,
            lpn: this.lpnSearchNum,
            all: 0,
            searchStr: ""
          };
          let showMessage = false;
          let data = await materialService.lpnActivityReport("post", obj, showMessage);
          if (data.message !== "NA") {
            this.reasonList = data;
            this.lpnSelected = true;
            this.showContinue = false;
            this.showReason = true;
          }
        }
      }
    },
    //Run Report
    async runReport() {
      let obj = {
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        pass: 2,
        lpn: this.lpnSearchNum,
        all: this.lpnAll == true ? 1 : 0,
        reason_key: parseInt(this.selectedReason),
        searchStr: ""
      };
      this.getReportData(obj)

    },
    async getReportData(obj) {
      let data = await materialService.lpnActivityReport("post", obj, false);
      if (data.message !== "NA") {
        this.totalRecords = data.length;
        this.exportDisable = false;
        const newArr = data.map((obj) => {
          return {
            ...obj,
            "Log Date": Utility.convertESTToLocal(obj["Log Date"]),
          };
        });
        this.lpnActivityData = newArr;
        this.showData = true;
      } else {
        this.totalRecords = 0;
        this.exportDisable = true;
        this.lpnActivityData = [];
        this.showData = false;
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        let obj = {
          user_id: parseInt(this.userId),
          lcsdate: Utility.convertLocalToUTC(this.sDate),
          lcedate: Utility.convertLocalToUTC(this.eDate),
          pass: 2,
          lpn: this.lpnSearchNum,
          all: this.lpnAll == true ? 1 : 0,
          reason_key: parseInt(this.selectedReason),
          searchStr: ""
        };
        this.excelName =
          "LPN_Activity_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/mm/lpn_activity_report", obj);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            "Log Date": Utility.convertESTToLocal(obj["Log Date"]),
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //ALL LPN switch change
    allLPNSwitch(){
      this.$refs.lpnDetailsForm.resetValidation();
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //to check if its a number of not
    isNumberWithHypen(event) {
      let value = Utility.isNumberWithHypen(event);
      return value;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
